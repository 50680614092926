import React from "react"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby-link"

import Banner from "../../components/banner"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Button } from "../../components/button"

function SupportPage() {
  const { register, handleSubmit, errors } = useForm()
  const onSubmit = async (data) => {
    if (data.checkf && data.checkf.length > 0) return

    const { email, name, subject, message } = data

    try {
      const res = await fetch("/.netlify/functions/submit-ticket", {
        method: "POST",
        body: JSON.stringify({
          email,
          name,
          subject,
          message,
          type: "technical-support",
        }),
      })

      if (res.ok) {
        const json = await res.json()

        if (json.data.error) {
          navigate(`/support/failure`)
        } else {
          navigate(`/support/success`)
        }
      } else {
        // Debugging
        // console.log('res not ok', res)

        navigate(`/support/failure`)
      }
    } catch (error) {
      // Debugging
      // console.log("error submitting request", error)
      navigate(`/support/failure`)
    }
  }

  return (
    <>
      <Seo title="Technical Support" />

      <Layout>
        <Banner label="Getting Help">Website &amp; Membership Support</Banner>

        <div className="container mx-auto px-3 py-6 lg:py-10">
          <p className="max-w-2xl text-xl mb-6">
            If you are experiencing issues when using the website or managing
            your online account, please complete the form below to raise a
            support ticket with our technical partners.
          </p>

          <form onSubmit={handleSubmit(onSubmit)} className="max-w-2xl">
            <dl>
              <dt>
                <label className="font-semibold">Name</label>
              </dt>
              <dd className="mb-6">
                <input
                  {...register("name")}
                  className="w-full px-4 py-2 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                />
              </dd>

              <dt>
                <label className="font-semibold" htmlFor="form1705-email">
                  E-Mail Address
                </label>
              </dt>
              <dd className="mb-6">
                <input
                  {...register("email")}
                  type="email"
                  className="w-full px-4 py-2 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                />
              </dd>

              <dt>
                <label className="font-semibold" htmlFor="form1705-subject">
                  Subject
                </label>
              </dt>
              <dd className="mb-6">
                <input
                  {...register("subject")}
                  className="w-full px-4 py-2 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                />
              </dd>

              <dt>
                <label className="font-semibold" htmlFor="form1705-message">
                  Message
                </label>
              </dt>
              <dd className="mb-6">
                <textarea
                  {...register("message")}
                  className="w-full px-4 py-2 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                />
              </dd>
            </dl>

            <Button size="medium" type="submit">
              Submit
            </Button>

            <input {...register("checkf")} style={{ display: "none" }} />
          </form>
        </div>
      </Layout>
    </>
  )
}

export default SupportPage
